import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { PortfolioPieceComponent } from './portfolio-piece/portfolio-piece.component';


// import { QuestionComponent } from './components/question/question.component';

@NgModule({
	imports: [CommonModule, RouterModule],
	declarations: [
    PortfolioPieceComponent
  ],
	providers: [],
	exports: [
		CommonModule,
		PortfolioPieceComponent
	],
})
export class SharedModule {}
