import { AfterViewInit, Component, Input } from '@angular/core';
import { PortfolioPeice } from 'src/app/_core/portfolio-pieces.model';

@Component({
  selector: 'app-portfolio-piece',
  templateUrl: './portfolio-piece.component.html',
  styleUrls: ['./portfolio-piece.component.scss']
})
export class PortfolioPieceComponent implements AfterViewInit {

  @Input() piece: PortfolioPeice;

  constructor(){

  }

  ngAfterViewInit(): void {
      console.log(this.piece)
  }

}
