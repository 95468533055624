export class PortfolioPeice {
    id: string;
    thumbnail: string;
    videoLink: string;
    title: string;
    description: string;
}

export const portfolio: Array<PortfolioPeice> = [
    {
        id: 'test',
        thumbnail: 'test',
        videoLink: 'test',
        title: 'test',
        description: 'test'
    }
]