import { Component } from '@angular/core';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent {
	constructor() {
		this.initializeApp();
	
		// Listen to the resize event.
		window.addEventListener('resize', this.fixVH);
		// Initialize.
		this.fixVH();
	}

	initializeApp() {}
	public fixVH() {
		// First, get the viewport height and multiply it by 1% to get a value for a vh unit.
		let vh = window.innerHeight * 0.01;
		// Then, set the value in the --vh custom property to the root of the document.
		document.documentElement.style.setProperty('--vh', `${vh}px`);
		console.log('Viewport Fix: 1vh = '+ vh + 'px;')
	}
}